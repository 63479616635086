.summary {
  padding: 32px;
  box-sizing: border-box;
  text-align: center;
}
.summary > h1 {
  padding: 0;
  margin: 0;
}

.summary::-webkit-scrollbar {
  display: none;
}

.summary-edd {
  text-decoration: underline;
}

.summary-content {
  text-align: left;
  margin-top: 32px;
}
.summary-content > p {
  font-weight: 500;
  margin: 0;
  padding: 8px 0;
  display: flex;
  justify-content: space-between;
}
.summary-content > p:first-child {
  font-size: 22px;
  border-bottom: 1px solid var(--theme-separator);
}
.summary-content > p:last-child {
  font-size: 16px;
  border-top: 1px solid var(--theme-separator);
}
.summary-content > ul {
  margin: 16px 0;
  padding: 0;
  list-style: none;
  font-size: 16px;
}
.summary-content > ul > li {
  padding: 8px 0;
  display: flex;
  justify-content: space-between;
}
.summary-content > ul > li > span:last-child {
  padding-left: 12px;
  text-transform: capitalize;
  opacity: var(--theme-medium-opacity);
}

.summary-content button {
  text-align: right;
  cursor: pointer;
  background: var(--theme-primary);
  color: white;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  line-height: 24px;
  outline: none;
  border: 2px solid transparent;
  border-radius: 25px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  /* max-width: 75px; */
  padding: 6px;
  box-sizing: border-box;
  transition: all .2s ease;
}
.summary-content button:focus, 
.summary-content button:active {
  box-shadow: inset 0 0 0 2px white;
}
.summary-content button:disabled {
  cursor: auto;
  opacity: 0.38;
}

.summary-content button > span {
  display: none;
}
.summary-content button > svg {
  min-width: 24px;
  min-height: 24px;
}

@media(min-width: 992px) {
  .summary {
    flex: 1;
    overflow-y: auto;
    min-width: 435px;
    padding: 48px;
	-ms-overflow-style: none;  /* IE and Edge */
	scrollbar-width: none;  /* Firefox */
  }
}